import { useEffect, useState } from "react";

export default function Advantages({ setSli, setFos, setType }) {
  const [active, setactive] = useState(0);
  const [active_2, setactive_2] = useState(0);
  const [event, setEvent] = useState();
  const [eventActive, setEventActive] = useState();
  useEffect(() => {
    fetch("/events_inform/events_inform.json")
    .then(r => r.json())
    .then(data => {
        setEvent(data)
        setEventActive(data[0].events[0])
    })
}, [])
  if (!event) {
    return "load..."
  }else{
    console.log(active);
    console.log(event);
    
  }
  return (
    <section className="activities" id="activities">
      <div className="container">
        <h2 className="h2">мероприятия</h2>
        <div className="activities_choice">
          {
            event.map((e,i)=>(
              <p key={i} className={active === i ?"select_month select_month_active":"select_month" } onClick={() => {setactive (i); setEventActive(e.events[0])}}>{e.month}</p>
            ))
          }
        </div>
        <div className="all_inform">
          <div className="list_events">
            {event[active].events.map((e,i)=>(
            <div key={i+"event"+active} className={active_2 === i ?"list_events_card list_events_card_active":"list_events_card" } onClick={() => {setactive_2 (i); setEventActive(e)}}>
              <div class="container_activities_picture">
                <img class="activities_main_picture"
                    src={
                        process.env.PUBLIC_URL +
                        e.picture
                    }
                    alt="Картинка"
                />
              </div>
              <div className="event_card_inform">
                <p className="activities_title">{e.title}</p>
                <div className="activities_location">
                  <img
                      src={
                          process.env.PUBLIC_URL +
                          "/img/activities/location.png"
                      }
                      alt="Локация"
                  />
                  <p className="activities_place">{e.location}</p>
                </div>
              </div>
            </div>
            ))}
        
          </div>
          <div className="detailed_inform">
            <div className="event_title_data">
              <p className="event_title">{eventActive.title}</p>
              <p className="event_data">{eventActive.date}</p>
            </div>
            <p
              dangerouslySetInnerHTML={{
                __html: eventActive.text,
              }}
              className="event_description"
              >
                {}
            </p>
            <div className="additional_inform">
              <div className="event_location">
                  <img
                      src={
                          process.env.PUBLIC_URL +
                          "/img/activities/location.png"
                      }
                      alt="Локация"
                  />
                  <p className="event_place">{eventActive.location}</p>
              </div>
              <div className="event_link">
                  <img
                      src={
                          process.env.PUBLIC_URL +
                          "/img/activities/link.png"
                      }
                      alt="Ссылка"
                  />
                  <p className="event_registration">Регистрация на мероприятие по ссылке: <a href={eventActive.link}>{eventActive.link}</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
