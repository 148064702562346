import { useState } from "react";
import LineContakt from "./lineContact";
import LineSpan from "./lineSpan";
import TabSlider from "./tabSlider";

export default function Advantages({ setSli, setFos, setType }) {
  const [active, setactive] = useState(true);
  return (
    <section className="advantages" id="advantages">
      <LineSpan />
      <div className="container">
        <div className="advantages_filter">
          <p className={!active ?"advantages_filter_item filter_item_first filter_item_black":"advantages_filter_item" } onClick={() => setactive (true)}>С визовым центром</p>
          <p className={active ?"advantages_filter_item filter_item_black":"advantages_filter_item" }  onClick={() => setactive (false)}>
            Самостоятельно
          </p>
        </div>
        <div
          className={
            active ? "with_visa_center_cards active" : "with_visa_center_cards"
          }
        >
          <div className="first_block">
            <div className="visa_center_card accent_block">
              <p className="advantages_title">
                С Китайским визовым центром в Москве вы получаете
              </p>
              <p className="advantages_description">
                Обращаясь в наш Визовый Центр, Вы получаете доступ к ряду
                преимуществ, которые упускаете при оформлении визы в Китай
                самостоятельно
              </p>
            </div>
            <div className="visa_center_card">
              <div className="card_number">
                <p>01</p>
              </div>
              <div className="card_description">
                <p className="card_title">
                  Профессиональную поддержку квалифицированных специалитов
                </p>
                <p className="card_text">
                  Сотрудники нашего визового центра предоставляют исчерпывающие
                  консультации, отвечают на любые возникающие у Вас вопросы и
                  помогают решить любые проблемы, связанные со сбором и
                  оформлением необходимых документов для подачи на визу в Китай
                </p>
              </div>
            </div>
            <div className="visa_center_card border_bottom">
              <div className="card_number">
                <p>02</p>
              </div>
              <div className="card_description">
                <p className="card_title">Гарантию качества оказанной услуги</p>
                <p className="card_text">
                  Обращаясь в наш Визовый центр, Вы можете быть уверены, что
                  весь необходимый для подачи пакет документов будет собран и
                  оформлен в соответствии со всеми требованиям Консульства
                </p>
              </div>
            </div>
          </div>
          <div className="second_block">
            <div className="visa_center_card border_top">
              <div className="card_number">
                <p>03</p>
              </div>
              <div className="card_description">
                <p className="card_title">
                  Оформление визы под ключ с минимальным пакетом документов
                </p>
                <p className="card_text">
                  Наш визовый центр не требует от Вас авиабилеты, бронирования
                  отелей и страховой полис, поэтому заняться планированием
                  поездки Вы сможете уже по факту получения готовой визы
                </p>
              </div>
            </div>
            <div className="visa_center_card">
              <div className="card_number">
                <p>04</p>
              </div>
              <div className="card_description">
                <p className="card_title">
                  Оперативную консультацию и своевременное оформление
                </p>
                <p className="card_text">
                  Каждому клиенту назначается персональный менеджер. При
                  постоянной связи с нашим менеджером, стандартный срок
                  оформления визы составит не более 5 дней
                </p>
              </div>
            </div>
            <div className="visa_center_card border_bottom">
              <div className="card_number">
                <p>05</p>
              </div>
              <div className="card_description">
                <p className="card_title">Удаленную подачу</p>
                <p className="card_text">
                  Отличительное преимущество нашего визового центра - оформление
                  визы без личного посещения Консульства Китая или офиса нашей
                  компании. Все документы можно подать в удаленном формате
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            !active ? "no_visa_center_cards active" : "no_visa_center_cards"
          }
        >
          <div className="first_block">
            <div className="visa_center_card accent_block">
              <p className="advantages_title">
                оформляя документы самостоятельно вы полуаете
              </p>
              <p className="advantages_description">
                Обращаясь в наш Визовый Центр, Вы получаете доступ к ряду
                преимуществ, которые упускаете при оформлении визы в Китай
                самостоятельно
              </p>
            </div>
            <div className="visa_center_card">
              <div className="card_number">
                <p>01</p>
              </div>
              <div className="card_description">
                <p className="card_title">
                  Самостоятельное изучение информации в Интернете
                </p>
                <p className="card_text">
                  Вам предстоит разобраться во всех текущих требованиях
                  Консульства Китая, собрать и оформить необходимый пакет
                  документов самостоятельно
                </p>
              </div>
            </div>
            <div className="visa_center_card border_bottom">
              <div className="card_number">
                <p>02</p>
              </div>
              <div className="card_description">
                <p className="card_title">
                  Отсутствие уверенности в правильности оформления документов
                </p>
                <p className="card_text">
                  В следствие этого, в случае неправильности оформления
                  документов, Вы теряете свое личное время и нервы
                </p>
              </div>
            </div>
          </div>
          <div className="second_block">
            <div className="visa_center_card">
              <div className="card_number">
                <p>03</p>
              </div>
              <div className="card_description">
                <p className="card_title">
                  Расширенный пакет документов для подачи
                </p>
                <p className="card_text">
                  При самостоятельном обращении в Консульство Китая за визой от
                  Вас будет требоваться более широкий список документов. Чаще
                  всего Консульство запрашивает авиабилеты, подтверждение
                  бронирования отелей, а также маршрутный лист на весь период
                  пребывания в Китае.
                </p>
              </div>
            </div>
            <div className="visa_center_card">
              <div className="card_number">
                <p>04</p>
              </div>
              <div className="card_description">
                <p className="card_title">
                  Затяжное изучение информации самостоятельно и длительная
                  подготовка без соответствующей информационной поддержки
                </p>
                <p className="card_text">
                  Во время подготовки соответствующего пакета документов у Вас
                  может возникнуть немало вопросов, которые важно прояснить для
                  оформления визы в Китай. Все это придется делать в одиночку.
                </p>
              </div>
            </div>
            <div className="visa_center_card border_bottom">
              <div className="card_number">
                <p>05</p>
              </div>
              <div className="card_description">
                <p className="card_title">
                  Необходимость записываться в Консульство, ожидание в очереди
                </p>
                <p className="card_text">
                  Время ожидания в очередях может составлять от 30 минут до
                  нескольких часов. В следствие этого, для успешной подачи
                  документов в Консульство необходимо выделять половину дня, в
                  худшем случае целый день
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
